<template>
	<div class="start">
		<h1 class="theme_text center">全台演透透的藝術壯旅</h1>
		<br>
		<div class="youtube">
			<iframe :src="`https://www.youtube.com/embed/1hGPijhhaS0`" frameborder="0" allowfullscreen />
		</div>
		<br>
		<div class="col" style="padding:10px;">
			<p>一個用戲劇，帶給全台民眾歡樂的簡單理念，催生了金枝演社與台灣母土締結的長期合約：「金枝走演‧美麗台灣」全台巡演。
				<br>2009年6月4日，從高雄市鳥松區踏出第一步，帶著劇團優質作品，發願演遍319個鄉鎮。</p>
			<p>這一步，開啟了一群傻子的夢，也開啟了這趟「幸福造夢」的藝術壯旅。</p>
			<p>多年風雨無阻，金枝自籌經費，透過民間及現場觀眾的涓滴贊助，以及地方協力，把金枝好戲送到全台每個地方，以行腳精神深入鬧市偏鄉、山城海村，邀請各地民眾免費看戲，重拾古早戲棚腳的單純美好：輕鬆看戲，散播歡樂。</p>
			<p>全台演透透，相招戲棚腳，「金枝走演‧美麗台灣」用戲劇許諾希望：「走」遍全台灣；「演」遍全台灣；讓藝術之花飄香全台。</p>
		</div>
		<div class="center">
			<a href="https://www.facebook.com/gbt.beautiful.taiwan" target="_blank">
				<img src="@/assets/perform/start/fb_btn.png" width="177">
			</a>
		</div>
		<br>
		<br>
		<carousel :options="{interval: 5000, loop: true, duration: 1000}" control indicator>
			<template v-for="src in images">
				<img :src="src" width="100%" :key="src">
			</template>
		</carousel>
	</div>
</template>

<script>
export default {
	components: {
		Carousel: () => import("@/components/carousel"),
	},
	data() {
		return {
			images: [
				require("@/assets/perform/start/carousel/1.jpg"),
				require("@/assets/perform/start/carousel/2.jpg"),
				require("@/assets/perform/start/carousel/3.jpg"),
				require("@/assets/perform/start/carousel/4.jpg"),
				require("@/assets/perform/start/carousel/5.jpg"),
				require("@/assets/perform/start/carousel/6.jpg"),
				require("@/assets/perform/start/carousel/7.jpg"),
				require("@/assets/perform/start/carousel/8.jpg"),
				require("@/assets/perform/start/carousel/9.jpg"),
			]
		}
	}
}
</script>